export default {
  Message: {
    Confirm: '確認',
    Cancel: '關閉',
    Title: '信息',
    msg401: '權限不足！',
    msg400: '系統異常！',
    msg404: '請求接口不存在！',
    msg500: '服務器錯誤！',
    msg502: '网络错误！',
    msg503: '服務不可用，服務器暫時過載或維護。',
    SucceedInOperating: '操作成功',
    SubmitFailed: '提交失敗'
  },
  DeliveryAddress: {
    Address: '地址'
  },
  Order: {
    OrderNumber: '訂單編號',
    OrderDateTime: '訂單日期&時間 ',
    TotalPrice: '總價',
    OrderStatus: '訂單狀態',
    Paid: '已付款',
    Unpaid: '待付款',
    PayNow: '立即支付'
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上傳文件(jpg, png, pdf)',
    UploadingText: '正在上傳',
    ConfirmText: '確定',
    UploadSuccessfulText: '上傳成功',
    UploadFailText: '上傳失敗',
    NoFileText: '沒有選中文件',
    UploadLengthText: '最多只能上載{0}檔案',
    UploadSizeText: '最多只能上傳{0}M',
    PleaseSelect: '請選擇',
    SubRecord: '提交紀錄',
    ReferenceNum: '參考編號',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '請選擇付款方式',
    PreviewTitleText: '請再次確認頁面',
    FormatErrorText: '請輸入正確電郵地址',
    RequiredText: '請檢查你的答案',
    Reuse: '使用'
  },
  Display: {
    SuccessCase: '成功個案',
    GeneralQuery: '壹般查詢',
    OfficeHours: '辦公時間',
    Hour0: '星期壹至五：',
    Hour1: '早上9時至下午12時,',
    Hour2: '下午1時30分至晚上6時',
    Hour3: '星期六、日及公眾期假休息',
    AddrInfo: '長沙灣荔枝角道 777號田氏企業中心 3樓 306室 (長沙灣港鐵站 B出口)'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即報名',
    ReturnHomePage: '返回首頁',
    OrderDetails: '訂單詳情'
  },
  Cms: {
    PlsChoose: '請點擊選擇您想要查看的內容：',
    CmsMore: '更多',
    Total: '共',
    Last: '上一頁',
    Next: '下一頁'
  },
  payment: {
    PaymentSuccessTips: '支付結果： 成功',
    PaymentFailTips: '支付結果： 失敗'
  },
  Calendar: {
    KnowMore: '了解更多',
    Time: '時間',
    Venue: '地點',
    NoEvent: '今日暫無活動'
  },
  photo: {
    photo: '相片',
    BackText: '返回上一級'
  },
  Account: {
    MemberCenter: '會員中心',
    MemberInformation: '用戶信息',
    MyMessages: '我的信箱',
    MyFavorite: '我的最愛',
    MyOrder: '購物訂單',
    DeliveryAddress: '送貨地址',
    Logout: '登出',
    Welcome: '歡迎!',
    SISA: '選擇成功',
    CSCLOrder: '集運訂單'
  },
  Login: {
    doLogin: '登入',
    LoginTitle: '會員登入',
    NewUser: '新用戶',
    RegNow: '現在注冊',
    ForgetPwd: '忘記密碼',
    RememberMe: '記住我',
    FaceBookUse: '使用facebook賬戶登錄',
    UserName: '登錄用戶名/郵箱地址',
    EnterEmail: '請輸入郵箱地址',
    CorrectEmail: '請輸入正確郵箱地址',
    LoginPwd: '請輸入密碼',
    LoginoldPwd: '密碼不正確',
    Loginerror: '賬號或密碼不正確',
    LoginSucceeded: '登入成功！',
    Account: '個人中心'
  },
  Register: {
    RegisterTitle: '註冊會員',
    UserEmail: '郵箱',
    or: '或',
    UserRegPassword: '密碼',
    UserNewPassword: '新密碼',
    UserOldPassword: '舊密碼',
    UserRegTips: '最少6個由字母或數字組成的字符',
    UserConfirmPassword: '確認密碼',
    UserFirstName: '名字',
    UserLastName: '姓氏',
    UserContactNumber: '聯繫電話',
    UserBirthDate: '出生日期',
    UserGender: '性別',
    UserMale: '男',
    UserFemale: '女',
    UserLanguage: '語言',
    UserLanguageE: '英文',
    UserLanguageT: '繁體',
    UserLanguageS: '簡體',
    RegisterBtn: '註冊',
    RegisterAgree: '我同意購買條款和使用條款',
    RegisterEmail: '郵箱不能為空',
    RegisterEmail01: '請輸入正式的郵箱格式',
    RegisterPassword: '請輸入密碼',
    RegisterNewPassword: '請再次輸入密碼',
    RegisterNewPassword01: '兩次輸入密碼不一致',
    RegisterFirstName: '請輸入您的姓名',
    RegisterLastName: '請輸入您的名稱',
    RegisterAgree_check: '請勾選',
    RegisterMobile: '請輸入您的手提電話',
    RegisterSuccess: '註冊成功',
    RegisterFail: '註冊失敗',
    RegisterWelcome: '恭喜您,註冊成功',
    RegisterTips: '此電子郵箱尚未註冊，請立即註冊成為會員！',
    Login: '跳轉登錄',
    Registerdigital: '長度由8至20個字符 須包含大寫字母、小寫字母及數字'
  },
  Forgetpassword: {
    ForgetPassword: '忘記密碼',
    ResetPwdTips: '請輸入您用於註冊的電子郵件，然後點擊“下一步”繼續',
    LoginNameEmail: '郵箱',
    NextStep: '下一步',
    ResetPwdSuccess: '重置密碼已發送到您的郵箱，請查看。',
    NotEmail: '收不到重設密碼電郵',
    ToReEnter: '按此重新填寫',
    EnterEmail: '請輸入郵箱地址',
    CorrectEmail: '請輸入正確的郵箱地址',
    ResetPwd: '重設密碼'
  },
  Input: {
    email: '請輸入正確的郵箱',
    number: '請輸入數字',
    input: '請輸入',
    text: '',
    date: '請選擇日期',
    password: '兩次輸入密碼不一致',
    textarea: '',
    emailNotFound: '郵箱不存在',
    logopassword: '請輸入密碼',
    confirmpassword: '兩次輸入密碼不一致',
    nomessage: '暫無數據',
    phoneincorrect: '電話號碼格式不正確',
    inputKey: '請輸入關鍵字'
  },
  CSCL: {
    Mandatory: 'This item is required',
    CreditCard: 'This item is required Or Save'
  },
  onlinefrom: {
    CorporateMembership: '企業會員申請表 — 香港',
    CompanyMembership: '公司會員申請表 — 香港',
    CorporateGroupMembership: '企業（團體）會員申請表 — 香港',
    Aboutthecompany: '有關申請公司',
    Membershipstatus: '會藉狀況',
    Renewal: '續會',
    Newmember: '新會藉',
    Nameoforganisation: '機構名稱',
    BRcertificateno: '商業登記證號碼',
    CINo: '公司登記證號碼',
    BRexpiredate: '商業登記屆滿日期',
    MailingaddressC: '郵寄地址（中文）',
    MailingaddressE: '郵寄地址（英文）',
    Generalline: '總機電話號碼',
    Fax: '傳真',
    Website: '網頁',
    Email: '電郵',
    KeyPersonnel: '公司負責人姓名',
    Chairman: '主席',
    PhonenoandNameofAssistant: '電話及助手姓名',
    Emailaddress: ' 電郵地址',
    GeneralManager: '總經理',
    MarketingDepartment: '市場部門責人姓名及職銜',
    NameandtitleoftheHead: '負責人姓名及職銜',
    ChinaandOverseasbranchifany: '國內及海外分公司（如有）',
    Companynameandaddress: '公司名稱及地址',
    TelephoneandEmail: '電話、電郵',
    NoofStaffinHKandOverseas: '香港及海外員工職員人數',
    NoofStaffinChinaoffice: '國內辦公室員工職員人數',
    NoofStaffinChinafactory: '國內廠房職員人數',
    Ourmutualagreement: '學會與貴機構的協議',
    TPA: '1 本人謹根據本表格所提供的資料及隨附的檔案，申請當選香港市場行銷學會（學會）會員，並同意接受學會關於本人有資格當選適當級別會員的决定。',
    TPB: '2 如果當選，我同意遵守組織大綱和公司章程中規定的規則和條例，並遵守協會專業實務守則的規定',
    TPC: '3 本人理解並同意，香港市場行銷學會的會員資格僅在準予的年度內有效，並支付會員費。未經批准或過期的成員不能使用任何指定。',
    TPD: '4 本人聲明此表格所提供的資料真實無誤。',
    TPE: '5 本人特此授權香港市場行銷學會在其認為合理和適當的情况下，與本人現時和以前的雇主及教育機構進行背景調查。',
    TPF: '6 如果我提供的教育和過去的就業資訊不充分，這將導致向研究所收取進行背景調查的任何費用，我同意承擔相關機構和我以前的雇主要求的所有費用。',
    TPG: '7 本機構謹此聲明，本申請表中填寫的資料，盡我所知，真確和完整。本人了解資料將在申請過程中使用，而且任何虛假記載，遺漏或誤導性的給出的信息可能會取消本機構的申請資格。',
    TPH: '8 本人須遵守《香港國際博物館私隱政策聲明》、《香港個人資料（私隱）條例》（第486章）及其他不時修訂的相關規例。',
    TPL: '9 本人聲明，據本人所知，為支持本申請而提供的數據是真實、準確和完整的。我理解這些數據將用於申請過程中，任何虛假陳述、遺漏或誤導性資訊可能會取消我的會員資格申請。',
    TPJ: '10 本人明白，在本人提出申請後，該等數據將成為HKIM記錄的一部分，並可根據HKIM的既定政策和《香港個人資料（隱私）條例》（第486章）為與本人學術和/或非學術活動有關的所有合法目的使用和處理。',
    DeclarationTitle: '聲明',
    Declaration: '本機構授權香港市務學會（HKIM）使用本機構的資料，為本機構直接提供市務消息，當中包括培訓和教育計劃，獎項及比賽，會員、校友、推廣活動，和一切學會安排等服務。 ',
    Pleaseticktheboxtoindicateyourconsent: ' 請在前面方框裡打勾以表示您的同意。',
    Pleaseticktheboxtoindicateyourobjection: ' 請在前面方框裡打勾以表示您的不同意。',
    StaffwhoarenominatedtoreceiveHKIMinformation: '接收市務學會資訊名單',
    Pleasenominate: "A. 請列出希望接收市務方面的最新培訓課程和資訊的員工, 並指定兩位聯絡代表作日常聯繫。 (請在兩位聯絡人前打'星星'作標記)",
    MrMs: '稱謂',
    Fullname: '全名',
    Currentposition: '職位',
    OfficeEmail: '公司電郵',
    PersonalEmail: '個人電郵',
    DirectLine: '直線',
    Paymentmethod: '付款辦法',
    MembershipFee: '公司會員(香港)會費表',
    NumberofstaffintheCompany: '公司員工人數',
    ApplicationFee: '入會申請費',
    Annualsubscriptionfor1year: '一年（12個月）會費',
    Annulsubscriptionfor2years: '二年（24個月）會費',
    Annualsubscriptionfor3years: '三年（36個月）會費',
    Notes: '備忘',
    Notapplicableformembershiprenewal: '續會免收。',
    Notaffected: '已繳費期內不受加費影響。',
    discountmembership: '一次過繳付三年，可獲九折優惠。',
    Wewouldliketopayinthefollowingmethod: '本機構選擇以下的付款方式。',
    CashPay: '現金 — 本公司派員親身到學會繳交。',
    Cheque: '支票，抬頭請寫「香港市務學會」有限公司.',
    EnclosedwiththechequeofHK$: '內附劃線支票港幣$',
    Chequeno: '支票號碼',
    Bank: '銀行',
    Banktransfer: '銀行入賬至香港匯豐銀行『香港市務學會』172-3-019178 戶口，並隨表附上收據副本',
    Wehaveprovided: '本公司已填妥以下資料及簽署，確定以信用卡繳付年費。本公司現在授權香港市務學會透過以下信用卡一次過收取已同意繳付之會費。',
    Creditcardtype: '信用卡類別',
    Personalcard: '私人信用卡',
    Companycard: '公司信用卡',
    CreditcardNo: '信用卡號碼',
    Expiry: '到期日',
    CardVerificationCode: '信用卡確認密碼',
    MembershipType: '會費類別',
    Sign: '签名',
    Save: '保存',
    paymentmethodA: '支付方式',
    Submit: '提交',
    ApplyDate: '申請日期',
    ApplyNo: '單號',
    DiscountAmount: '金額',
    Status: '狀態',
    Close: '關閉',
    Pay: '支付',
    AddRow: '增加行',
    RemoveRow: '删除',
    Correctemail: '請輸入正確的電郵',
    Required: '此項為必填項',
    highlight: "(請在兩位聯絡人前打'星星'作標記",
    individual: '提名為個人會員的員工',
    individualmembers: '請提名5名可享有個人會員會籍的員工',
    individualmembers1: '請提名5名可享有個人會員會籍的員工',
    Corporatemember: '企業會員(香港)會費表',
    CorporateGroupmemberg: '企業（團體）會員 - 香港會費表',
    people: '101 + 人',
    statement: '本公司的組織使命',
    statement1: '(請附上貴公司有關資料.)'
  },
  applicationfrom: {
    Renewal: '續會',
    Newmember: '新會藉',
    Reapply: '重新申請會籍 (會員如其會籍過期超過3年)',
    applying: '申請類別',
    Application: '入會 / 會籍升級 申請',
    Welcome: '香港市務學會歡迎您',
    Welcometest: '閣下正為您的專業展開新的一頁！您將會透過本會多姿多采的活動擴展您的人際網絡，並從我們出版的資訊刊物獲得最新 市場動態，更可透過我們為您度身訂造的專業及持續進修課程不斷增進您的知識，還有更多好處......您將可一一體驗。 請閣下花數分鐘時間，告訴本會有關您的經驗及背景，並請附上相關文件以便本會核實及釐訂您的會籍級別',
    information: '從本表格取得的資料，將作本會行政之用。請以英文正楷填寫',
    Personal: '閣下的個人資料',
    Personal1: '以下姓名必須與申請人護照／身份證上之名字相符，請提供副本以供核實及蓋上「副本」',
    Title: '先生／小姐',
    FirstName: '名字',
    LastName: '姓氏',
    ChineseName: '中文姓名',
    NickName: '英文名字',
    Nationality: '國籍',
    BirthDate: '出生日期',
    IDNo: '護照／身份證號碼',
    Male: '男',
    Female: '女',
    Contact: ' 閣下的通訊資料',
    Addr_Room: '室',
    Addr_Floor: '樓',
    Addr_Block: ' 座 ／大廈名稱',
    Addr_Street: ' 門牌號碼及街道名稱／屋邨',
    District: '地區',
    HongKong: '香港',
    Kowloon: '九龍',
    NewTerritories: '新界',
    Overseas: '海外',
    AddrOverseas: '請列明香港以外地址包括省、市、及郵政編碼',
    Mobile: '手提電話',
    HomeTel: '住宅電話',
    HomeFax: '住宅傳真',
    Email: '主要通訊電郵',
    EmailAdditional: '其他通訊電郵',
    Contactemails: '閣下的電郵資料',
    appointment: '閣下現時的工作',
    OrganName: '機構名稱',
    OrganStaffRangeType: '機構人數',
    OrganStaffRangeType1: '1~20人',
    OrganStaffRangeType2: '21~50人',
    OrganStaffRangeType3: '51~100人',
    OrganStaffRangeType4: '100人以上',
    JobTitle: '現職',
    AppointedDate: '出任此職位之日期',
    DirectLine: '直線號碼',
    CompanyAddr: '公司地址',
    CompanyStreet: '門牌號碼及街道名稱',
    CompanyDistrictType: '地區',
    DLine: '傳真號碼',
    MLine: '總機號碼',
    ResponsibilitiesRemarks: '閣下之市務管理範疇 (可包括您管理的員工數目及預算，或可附上閣下的履歷表)',
    IndustrialCatType: '閣下從事工作的類別',
    Advertising: 'Advertising/Designs/Packaging',
    Hospitality: 'Hospitality/Tourism/Catering/Restaurant',
    Business: 'Business Development',
    ChinaTrade: 'China Trade & Services',
    DirectSelling: 'Direct Selling/Telemarketing',
    FMCGMarketing: 'FMCG Marketing/Distribution',
    GeneralMarketing: 'General Marketing/Market Research & Planning',
    MediaJournalism: 'Media/Journalism/Publication',
    EventsExhibition: 'P.R./Events/Exhibition/Marketing Projects',
    SocialMedia: 'Social Media/I.T./e-commerce',
    Retailing: 'Retailing/CRM/Customer Service/Visual Merchandising',
    Trading: 'Trading/Wholesaling/Merchandising/Products Sourcing/General Trading',
    Academic: 'Academic/Education',
    Corporate: 'Corporate Services',
    Engineering: 'Engineering',
    Medical: 'Medical',
    Transport: 'Transport & Logistics',
    General: 'General Management/MNC',
    Government: 'Government/Non-profit',
    Training: 'HR/Training',
    Manufacturing: 'Manufacturing/Industrial',
    Others: 'Others',
    provide: '如提供履歷則不用填寫第5及第6項',
    Previous: '以往的市務工作經驗（請以倒序時間排列）',
    Previous1: '由',
    Previous2: '至',
    Previous3: '公司名稱',
    Previous4: '職位',
    Previous5: '市務管轄範圍',
    educational: '閣下的教育資歷及專業資格 (請以時間倒序排列)',
    educational1: '由',
    educational2: '至',
    educational3: '頒授院校或專業團體',
    educational4: '所獲專業資格／會籍',
    educational5: '主修科目',
    HowHKIM: '如何認識本會',
    Introduce: '本會會員介紹',
    Namereferee: '介紹人姓名',
    HKIMwebsite: '本會網頁',
    Newsletter: '本會電子傳訊/發佈',
    Exhibition: '博覽會/講座/座談會',
    ProM: '香港專業巿務師',
    qualifications: '本人欲索取有關香港專業市務師資格之資料',
    agreement: '學會與閣下的協議',
    payment: '付款辦法',
    payment1: '本人現繳付會員申請費用港幣三百五十元正，並知悉此費用將不予發還。餘額將會在會籍批核後收取或發還，並知悉如未能成功申請時獲得此筆預繳年費之發還。',
    Wehaveprovided: '本人填妥以下資料及簽署，確定以信用卡繳付港幣$350首次申請費用及港幣$980年費 (合共港幣$1,330)',
    Collection: '本人會親臨學會拿取證書',
    Collection1: '請將證書寄到本人登記地址 (公司 Office/ 住宅 Home)',
    IsStarTargettip: '只能勾选2个'
  },
  StudentFrom: {
    StudentMembershipApplication: '學生會員會籍申請表',
    ForFullTimeStudentsOnly: '只供全日制學生申請',
    TipsA: '透過會員之間互相交流和參加一系列的機構參觀，巿務營銷專題講座，本會的學生會員可接觸和更新巿務營銷的知識，及擴大社交圈子。另外， 學生會員可以優惠費用參加本會舉辦之活動。',
    TipsB: '本人現正申請加入香港巿務學會學生會員，並同意呈上學歷證明以供香港巿務學會作會籍審批用途。 (請將此表格連同香港身份證及學生證副本 一併遞交以申請學生會員會籍。)',
    PersonalDetails: '個人資料',
    Title: '名銜',
    Mr: '先生',
    Ms: '女士',
    SurnameEnglish: ' 姓 (英文)',
    ForenameEnglish: '名 (英文)',
    Nickname: '英文別名',
    NameinChinese: '中文姓名',
    PassportNo: '護照/身份證號碼',
    Dateofbirth: '出生日期',
    eMailA: '電郵(1)',
    eMailB: '電郵(2)',
    EmailTips: '*本會將會以電郵與學生保持聯絡。為此，請提供最常用之電郵。',
    Mobile: '手提電話',
    HomeTelNo: '住宅電話',
    MailingAddress: '通訊地址',
    DistrictType: ' 地址所在地區',
    HongKong: '香港',
    Kowloon: '九龍',
    NewTerritories: '新界',
    Overseas: '海外',
    StudentMembership: '學生會籍',
    coursetitle: ' 請填上課程名稱及修讀年份:',
    NameofInstitute: '學院名稱',
    Programme: '課程',
    Period: '時期',
    courseTipsA: '本人茲證 明本表格所載各項均屬實無訛。本人現正式申請註冊成為香港市務學會學生會員，並承諾在成功註冊後，成為香港市務學會會員一份子時遵守本學會所訂的守則及其修訂本。本人 明白並同意香港市務學會的會籍只於所批核的年期內生效。本人並授權香港市務學會向有關人士或機構查證及核實本人之背景包括本人曾就讀的各所院校。',
    courseTipsB: '本人將遵守香港市務學會私隱政策聲明，香港個人資料（私 隱）條例（第 486 章）及其他相關規定，及其改變。本人謹此聲明，本申請表中填寫的資料，盡我所知，真確和完整。本人了解資料將在申請過程中使用，而且任何虛假記載，遺 漏或誤導性的給出的信息可能會取消本人的申請資格。本人明白是次申請，資料將成為香港市務學會的記錄，並可按照香港市務學會及香港的既定政策在合法情況下根據香港個人 資料（私隱）條例（第 486 章）用於有關本人的學歷及/或非學術活動。',
    Declaration: '聲明',
    DeclarationTips: '本人授權香港市務學會（HKIM）使用本人的資料， 為本人直接提供市務消息，當中包括培訓和教育計劃，獎項及比賽，會員、校友、推廣活動，和一切學會安排等服務。 ',
    Submittedsuccessfully: '提交成功'
  }
};
