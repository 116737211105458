export default {
  Message: {
    Confirm: '确认',
    Cancel: '关闭',
    Title: '信息',
    msg401: '权限不足！',
    msg400: '系统异常！',
    msg404: '请求接口不存在！',
    msg500: '服务器错误！',
    msg502: '网络错误！',
    msg503: '服务不可用，服务器暂时过载或维护。',
    SucceedInOperating: '操作成功',
    SubmitFailed: '提交失败'
  },
  DeliveryAddress: {
    Address: '地址'
  },
  Order: {
    OrderNumber: '订单编号',
    OrderDateTime: '订单日期&时间 ',
    TotalPrice: '总价',
    OrderStatus: '订单状态',
    Paid: '已付款',
    Unpaid: '待付款',
    PayNow: '立即支付'
  },
  RegNPay: {
    BackText: '返回',
    UploadButtonText: '上传文件(jpg, png, pdf)',
    UploadingText: '正在上传',
    ConfirmText: '确定',
    UploadSuccessfulText: '上传成功',
    UploadFailText: '上传失败',
    NoFileText: '没有选中文件',
    UploadLengthText: '最多只能上载{0}文件',
    UploadSizeText: '最多只能上传{0}M',
    PleaseSelect: '请选择',
    SubRecord: '提交纪录',
    ReferenceNum: '参考编号',
    SubmissionDate: '提交日期',
    Print: '列印',
    PlsPayWay: '请选择付款方式',
    PreviewTitleText: '请再次确认页面',
    FormatErrorText: '请输入正确电邮地址',
    RequiredText: '请检查你的答案'
  },
  Display: {
    SuccessCase: '成功个案',
    GeneralQuery: '一般查询',
    OfficeHours: '办公时间',
    Hour0: '星期一至五：',
    Hour1: '早上9时至下午12时,',
    Hour2: '下午1时30分至晚上6时',
    Hour3: '星期六、日及公众期假休息',
    AddrInfo: '长沙湾荔枝角道 777号田氏企业中心 3楼 306室 (长沙湾港铁站 B出口)'
  },
  Action: {
    ViewMore: '查看更多',
    SignUp: '立即报名',
    ReturnHomePage: '返回首页',
    OrderDetails: '订单详情'
  },
  Cms: {
    PlsChoose: '请点击选择您想要查看的内容：',
    CmsMore: '更多',
    Total: '共',
    Last: '上一頁',
    Next: '下一頁'
  },
  payment: {
    PaymentSuccessTips: '支付结果： 成功',
    PaymentFailTips: '支付结果： 失败'
  },
  Calendar: {
    KnowMore: '了解更多',
    Time: '时间',
    Venue: '地点',
    NoEvent: '今日暂无活动'
  },
  photo: {
    photo: '相片',
    BackText: '返回上一级'
  },
  Account: {
    MemberCenter: '会员中心',
    MemberInformation: '用户信息',
    MyMessages: '我的信箱',
    MyFavorite: '我的最爱',
    MyOrder: '购物订单',
    DeliveryAddress: '送货地址',
    Logout: '登出',
    Welcome: '欢迎!',
    SISA: '选择成功',
    CSCLOrder: '集运订单'
  },
  Login: {
    doLogin: '登入',
    LoginTitle: '会员登入',
    NewUser: '新用户',
    RegNow: '现在注册',
    ForgetPwd: '忘记密码',
    RememberMe: '记住我',
    FaceBookUse: '使用facebook账户登录',
    UserName: '登录用户名/邮箱地址',
    EnterEmail: '请输入邮箱地址',
    CorrectEmail: '请输入正确邮箱地址',
    LoginPwd: '请输入密码',
    LoginoldPwd: '密码不正确',
    Loginerror: '账号或密码不正确',
    LoginSucceeded: '登入成功！',
    Account: '个人中心'
  },
  Register: {
    RegisterTitle: '注册会员',
    UserEmail: '邮箱',
    or: '或',
    UserRegPassword: '密码',
    UserNewPassword: '新密码',
    UserOldPassword: '旧密码',
    UserRegTips: '最少6个由字母或数字组成的字符',
    UserConfirmPassword: '确认密码',
    UserFirstName: '名字',
    UserLastName: '姓氏',
    UserContactNumber: '联系电话',
    UserBirthDate: '出生日期',
    UserGender: '性别',
    UserMale: '男',
    UserFemale: '女',
    UserLanguage: '语言',
    UserLanguageE: '英文',
    UserLanguageT: '繁体',
    UserLanguageS: '简体',
    RegisterBtn: '注册',
    RegisterAgree: '我同意购买条款和使用条款',
    RegisterEmail: '邮箱不能为空',
    RegisterEmail01: '请输入正式的邮箱格式',
    RegisterPassword: '请输入密码',
    RegisterNewPassword: '请再次输入密码',
    RegisterNewPassword01: '两次输入密码不一致',
    RegisterFirstName: '请输入您的姓名',
    RegisterLastName: '请输入您的名称',
    RegisterAgree_check: '请勾选',
    RegisterMobile: '请输入您的手提电话',
    RegisterSuccess: '注册成功',
    RegisterFail: '注册失败',
    RegisterWelcome: '恭喜您,注册成功',
    RegisterTips: '此电子邮箱尚未注册，请立即注册成为会员！',
    Login: '跳转登录',
    Registerdigital: '长度由8至20个字符 须包含大写字母、小写字母及数字'
  },
  Forgetpassword: {
    ForgetPassword: '忘记密码',
    ResetPwdTips: '请输入您用于注册的电子邮件，然后点击“下一步”继续',
    LoginNameEmail: '邮箱',
    NextStep: '下一步',
    ResetPwdSuccess: '重置密码已发送到您的邮箱，请查看。',
    NotEmail: '收不到重设密码电邮',
    ToReEnter: '按此重新填写',
    EnterEmail: '请输入邮箱地址',
    CorrectEmail: '请输入正确的邮箱地址',
    ResetPwd: '重设密码'
  },
  Input: {
    email: '请输入正确的邮箱',
    number: '请输入数字',
    input: '请输入',
    text: '',
    date: '请选择日期',
    password: '两次输入密码不一致',
    textarea: '',
    emailNotFound: '邮箱不存在',
    logopassword: '请输入密码',
    confirmpassword: '两次输入密码不一致',
    nomessage: '暂无数据',
    phoneincorrect: '电话号码格式不正确',
    inputKey: '请输入关键字'
  },
  CSCL: {
    Mandatory: 'This item is required',
    CreditCard: 'This item is required Or Save'
  },
  applicationfrom: {
    Renewal: 'Renewal',
    Newmember: 'New member',
    Reapply: 'Reapply for membership (if the membership expires for more than 3 years)'
  }
};
