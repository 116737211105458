export default {
  Message: {
    Confirm: 'Ok',
    Cancel: 'Close',
    Title: 'Message',
    msg401: 'Insufficient authority!',
    msg400: 'System abnormal!',
    msg404: 'Request interface does not exist!',
    msg500: 'Server error!',
    msg502: 'Network error!',
    msg503: 'The service is not available, the server is temporarily overloaded or maintained.',
    SucceedInOperating: 'Succeed in Operating',
    SubmitFailed: 'Submit Failed'
  },
  DeliveryAddress: {
    Address: 'Address'
  },
  Order: {
    OrderNumber: 'Order Number',
    OrderDateTime: 'Order Date & Time',
    TotalPrice: 'TotalPrice',
    OrderStatus: 'Order Status',
    Paid: 'Paid',
    Unpaid: 'Unpaid',
    PayNow: 'Pay Now'
  },
  RegNPay: {
    BackText: 'Back',
    UploadButtonText: 'Upload files(jpg, png, pdf)',
    UploadingText: 'Uploading...',
    ConfirmText: 'OK',
    UploadSuccessfulText: 'Upload Successful',
    UploadFailText: 'Upload failed',
    NoFileText: 'No file selected',
    UploadLengthText: 'You can only upload {0} files at most',
    UploadSizeText: 'You can only upload {0} M at most',
    PleaseSelect: 'Please Select',
    SubRecord: 'Submission Record',
    ReferenceNum: 'Reference Number',
    SubmissionDate: 'Submission Date',
    Print: 'Print',
    PlsPayWay: 'Please select the mode of payment',
    PreviewTitleText: 'Please confirm your information again.',
    FormatErrorText: 'Please input correct email address.',
    RequiredText: 'Required',
    Reuse: 'Use'
  },
  Display: {
    SuccessCase: 'Successful Case',
    GeneralQuery: 'General Query',
    OfficeHours: 'Office Hours',
    Hour0: 'Monday to Friday：',
    Hour1: '9:00 a.m. to 12:00 p.m.,',
    Hour2: 'From 1:30 p.m. to 6 p.m',
    Hour3: 'Closed on Saturdays, Sundays and public holidays',
    AddrInfo: "Rm 306, 3 / f, tin's enterprise centre, 777 lai chi kok road, cheung sha wan (cheung sha wan MTR station exit B)"
  },
  Action: {
    ViewMore: 'View More',
    SignUp: 'Sign Up',
    ReturnHomePage: 'Return Home Page',
    OrderDetails: 'Order Details'
  },
  Cms: {
    PlsChoose: 'Please click to select the content you want to view :',
    CmsMore: 'More',
    Total: 'Total',
    Last: 'PREVIOUS',
    Next: 'NEXT',
    per: '第',
    page: '頁'
  },
  payment: {
    PaymentSuccessTips: 'Payment Result : Success',
    PaymentFailTips: 'Payment Result : Fail'
  },
  Calendar: {
    KnowMore: 'More',
    Time: 'Time',
    Venue: 'Venue',
    NoEvent: 'No activity today'
  },
  photo: {
    photo: 'Photo Gallery',
    BackText: 'Back to previous'
  },
  Account: {
    MemberCenter: 'Member Center',
    MemberInformation: 'Member Information',
    MyMessages: 'My Messages',
    MyFavorite: 'My Favorite',
    MyOrder: 'My Order',
    DeliveryAddress: 'Delivery Address',
    Logout: 'Logout',
    Welcome: 'WELCOME!',
    SISA: 'Succeed in Choosing'
  },
  Login: {
    doLogin: 'Login',
    LoginTitle: 'Login',
    NewUser: 'New buyer',
    RegNow: 'Register now',
    ForgetPwd: 'Forget Password',
    RememberMe: 'Remember Me',
    FaceBookUse: 'Login with FaceBook',
    UserName: 'Login Name/Email',
    EnterEmail: 'Please Enter The Email',
    CorrectEmail: 'Please Enter The Correct Email',
    LoginPwd: 'Please enter your password',
    LoginoldPwd: 'Incorrect password',
    Loginerror: 'Incorrect account or password',
    LoginSucceeded: 'Login Succeeded',
    Account: 'Account'
  },
  Register: {
    RegisterTitle: 'Registration',
    UserEmail: 'Email',
    or: 'or',
    UserRegPassword: 'Password',
    UserNewPassword: 'New Password',
    UserOldPassword: 'Old Password',
    UserRegTips: 'Min.6 characters with letters and numbers',
    UserConfirmPassword: 'Confirm',
    UserFirstName: 'First Name',
    UserLastName: 'Last Name',
    UserContactNumber: 'Phone',
    UserBirthDate: 'Birth Date',
    UserGender: 'Gender',
    UserMale: 'Male',
    UserFemale: 'Female',
    UserLanguage: 'Language',
    UserLanguageE: 'English',
    UserLanguageT: 'Traditional Chinese',
    UserLanguageS: 'Simplified Chinese',
    RegisterBtn: 'Register',
    RegisterAgree: 'I agree to the terms of purchase and terms of use',
    RegisterEmail: 'Email cannot be empty',
    RegisterEmail01: 'Please enter a formal mailbox format',
    RegisterPassword: 'Please enter your password',
    RegisterNewPassword: 'Please enter your password again',
    RegisterNewPassword01: 'Two inconsistent input passwords',
    RegisterFirstName: 'Please enter your name',
    RegisterLastName: 'Name please enter your name',
    RegisterAgree_check: 'Please check',
    RegisterMobile: 'Please enter your phone',
    RegisterSuccess: 'Register Succeeded',
    RegisterFail: 'Register Failed',
    RegisterWelcome: 'Congratulations on your successful registration',
    RegisterTips: 'This email address has not been registered, register to become a member immediately!',
    Login: 'Login now!',
    Registerdigital: 'Length from 8 to 20 must contain uppercase letters, lowercase letters and numbers'
  },
  Forgetpassword: {
    ForgetPassword: 'Forget Password',
    ResetPwdTips:
      'Please enter the registered email you used for registration, then click Next to continue',
    LoginNameEmail: 'Email',
    NextStep: 'Next',
    ResetPwdSuccess: 'Temp Password is sended to your email,please check.',
    NotEmail: 'Cannot receive the Activate Email',
    ToReEnter: 'Click here to re-enter',
    EnterEmail: 'Please Enter The Email',
    CorrectEmail: 'Please Enter The Correct Email',
    ResetPwd: 'Reset Password'
  },
  Input: {
    email: 'Please enter the correct email',
    number: 'Please enter a number',
    input: 'Please input ',
    text: '',
    date: 'Please enter a date',
    password: 'Two inconsistent input passwords',
    textarea: '',
    emailNotFound: 'Email Not Found',
    logopassword: 'Please input passwords',
    confirmpassword: 'Two inconsistent input passwords',
    nomessage: 'No Message',
    phoneincorrect: 'The phone number format is incorrect'
  },
  CSCL: {
    Mandatory: 'This item is required',
    CreditCard: 'This item is required Or Save'
  },
  onlinefrom: {
    CorporateMembership: 'Corporate Membership Application Form - Hong Kong',
    CompanyMembership: 'Company Membership Application Form - Hong Kong',
    CorporateGroupMembership: 'Corporate (Group) Membership Application Form - Hong Kong',
    Aboutthecompany: ' About the company',
    Membershipstatus: 'Membership status',
    Renewal: 'Renewal',
    Newmember: 'New member',
    Nameoforganisation: 'Name of organisation',
    BRcertificateno: 'B.R. certificate no.',
    CINo: 'CI No.',
    BRexpiredate: 'B.R. expire date',
    MailingaddressC: 'Mailing address(Chinese)',
    MailingaddressE: 'Mailing address(English)',
    Generalline: 'General line',
    Fax: 'Fax',
    Website: 'Web site',
    Email: 'Email',
    KeyPersonnel: 'Key Personnel',
    Chairman: 'Chairman',
    PhonenoandNameofAssistant: 'Phone no. and Name of Assistant',
    Emailaddress: ' Email address',
    GeneralManager: 'General Manager',
    MarketingDepartment: 'Name and title of the Head of Marketing Department ',
    NameandtitleoftheHead: 'Name and title of the Head',
    ChinaandOverseasbranchifany: 'China and Overseas branch(es), if any',
    Companynameandaddress: 'Company name and address',
    TelephoneandEmail: 'Telephone and Email',
    NoofStaffinHKandOverseas: 'No. of Staff in HK and Overseas',
    NoofStaffinChinaoffice: 'No. of Staff in China office',
    NoofStaffinChinafactory: 'No. of Staff in China factory',
    Ourmutualagreement: ' Our mutual agreement',
    TPA: '1  I hereby apply for election to membership of Hong Kong Institute of Marketing (the Institute) on the basis of the information provided in this form and documents enclosed with it and agree to accept the decision of the Institute as to my eligibility for the election to the appropriate grades of membership. ',
    TPB: "2  If elected, I agree to abide by the rules and regulations as set out in the Memorandum and Articles of Association and also to observe the provisions of the Institute's professional code of practice",
    TPC: '3  I understand and agree that memberships of Hong Kong Institute of Marketing are valid only for the year(s) approved and with membership fees paid. No designation can be used by unapproved or expired members. ',
    TPD: '4  I declare that the information given in this form is true and correct. ',
    TPE: '5  I hereby authorise HONG KONG INSTITUTE OF MARKETING to conduct background check with my current and previous employers and educational institutes which I attended as the Institute deem reasonable and appropriate.',
    TPF: '6  If the provision of my educational and past employment information is not sufficient, which should induce any fees charged to the Institute for conducting background check, I agree to bear all the fees requested by relevant institutions and my previous employers',
    TPG: '7  I declare all information in this form is true and correct.',
    TPH: '8  I shall comply with the HKIM Privacy Policy Statement, the Hong Kong Personal Data (Privacy) Ordinance (Cap.486) and other  related regulations which will be changed from time to time. ',
    TPL: '9  I declare that the data given in support of this application are, to the best of my knowledge, true, accurate and complete. I understand that the data will be used in the application process and that any misrepresentation, omission or misleading information given may disqualify my application for membership.  ',
    TPJ: '10  I understand that, upon my application, the data will become part of the HKIM record and may be used and processed for all lawful purposes relating to my academic and/or non-academic activities in accordance with the established policy of the HKIM  and the Hong Kong Personal Data (Privacy) Ordinance (Cap.486). ',
    DeclarationTitle: 'Declaration',
    Declaration: 'I authorize Hong Kong Institute of Marketing (HKIM) to use my data to keep me informed of any direct marketing information including training and education programmes, awards and competitions, membership, alumni, promotional activities and other services and activities that it may arrange. ',
    Pleaseticktheboxtoindicateyourconsent: 'Please tick the box to indicate your consent.',
    Pleaseticktheboxtoindicateyourobjection: 'Please tick the box to indicate your objection.',
    StaffwhoarenominatedtoreceiveHKIMinformation: 'Staff who are nominated to receive HKIM information',
    Pleasenominate: "A. Please nominate as many as your staff to receive our latest marketing and courses information, and advise 2 names for daily contact. (Please highlight the two contact person with a 'star')",
    MrMs: 'Mr/Ms',
    Fullname: 'Full name',
    Currentposition: 'Current position',
    OfficeEmail: 'Office Email',
    PersonalEmail: 'Personal Email',
    DirectLine: 'Direct Line',
    Paymentmethod: 'Payment method',
    MembershipFee: 'Membership Fee for Company member (Homg Kong)',
    NumberofstaffintheCompany: 'Number of staff in the Company',
    ApplicationFee: 'Application Fee',
    Annualsubscriptionfor1year: 'Annual subscription for 1 year.',
    Annulsubscriptionfor2years: 'Annul subscription for 2 years.',
    Annualsubscriptionfor3years: 'Annual subscription for 3 years',
    Notes: 'Notes',
    Notapplicableformembershiprenewal: 'Not applicable for membership renewal.',
    Notaffected: 'Not affected even the rate increased during membership-paid period.',
    discountmembership: '10% discount of 3-year membership, pay in one time.',
    Wewouldliketopayinthefollowingmethod: 'We would like to pay in the following method.',
    CashPay: 'Cash: Pay in-person only.',
    Cheque: "Cheque made payable to 'Hong Kong Institute of Marketing Limited'",
    EnclosedwiththechequeofHK$: 'Enclosed with the cheque of HK$',
    Chequeno: 'Cheque no.',
    Bank: 'Bank',
    Banktransfer: "Bank transfer to HSBC A/C. 172-3-019178 payable to 'Hong Kong Institute of Marketing Limited', with a copy of receipt.",
    Wehaveprovided: 'We have provided our credit card information and signing below, we hereby authorize Hong Kong Institute of Marketing to charge the following credit card with the full amount for the agreed membership fees.',
    Creditcardtype: 'Credit card type',
    Personalcard: 'Personal card',
    Companycard: 'Company card',
    CreditcardNo: 'Credit card No.',
    Expiry: 'Expiry',
    CardVerificationCode: 'Card Verification Code',
    MembershipType: 'Membership Type',
    Sign: 'Signature ',
    Save: 'Save',
    paymentmethodA: 'Payment method',
    Submit: 'Submit',
    ApplyDate: 'Apply Date',
    ApplyNo: 'Apply No',
    DiscountAmount: 'Discount Amount',
    Status: 'Status',
    Close: 'Close',
    Pay: 'Pay',
    AddRow: 'Add Row',
    RemoveRow: 'Remove',
    Correctemail: 'Please enter the correct email format',
    Required: 'This item is required',
    highlight: "Please highlight the two contact person with a 'star'",
    individual: 'who are nominated as individual members',
    individualmembers: 'Please nominate 5 staff to be nominated as individual members',
    individualmembers1: ' Please nominate 10 staff to be nominated as individual members',
    Corporatemember: 'Membership Fee for Corporate member (Homg Kong)',
    CorporateGroupmemberg: 'Membership Fee for Corporate(Group) member - Hong Kong',
    people: '101 + people',
    statement: 'Please provide the mission statement',
    statement1: '(Please attach relevant documents with this form.)'
  },
  applicationfrom: {
    Renewal: 'Renewal',
    Newmember: 'New membership',
    Reapply: 'Re-apply membership (for members whose membership expired for more than 3 years)',
    applying: 'You are applying for',
    Application: 'Application for Membership / Membership-Upgrade',
    Welcome: 'Welcome to the Hong Kong Institute of marketing',
    Welcometest: 'You are beginning a new journey in your professional life. You will develop a new network through our activities, receive atest market intelligence from our publications, enhance your knowledge through our education and continuing professional development programme, and… many more! Please spend a few minutes to tell us your experience and background. Please also attach relevant documents for us to  verify your status and to decide your membership grade.',
    information: 'Information collected through this form will be used for administrative purposes by the Institute.',
    Personal: 'Personal details',
    Personal1: 'The below surname and forenames must be the same as applicant s passport/I.D. names, please provide a copy for verficiation',
    Title: 'Mr. / Miss',
    FirstName: 'Name',
    LastName: 'Surname',
    ChineseName: 'Chinese Name',
    NickName: 'English names',
    Nationality: 'Nationality',
    BirthDate: 'Date of birth',
    IDNo: 'Passport / I.D. no. (Please provide the first 5 characters of your HKID No. only, e.g. A3456XX)',
    Male: 'Male',
    Female: 'Female',
    Contact: 'Contact details',
    Addr_Room: 'Flat / Room',
    Addr_Floor: 'Floor',
    Addr_Block: 'Block / Name of building',
    Addr_Street: 'Number and name of street and/or estate',
    Addr_Name: 'Name',
    District: 'District',
    HongKong: 'Hong Kong',
    Kowloon: 'Kowloon',
    NewTerritories: 'New Territories',
    Overseas: 'Overseas',
    AddrOverseas: 'If outside Hong Kong, please tell us the city, province, country & postal code',
    Mobile: 'Mobile Phone No.',
    HomeTel: 'Home tel',
    HomeFax: 'Home fax',
    Email: 'Primary email for regular contact',
    EmailAdditional: 'Additional email',
    Contactemails: 'Contact emails',
    appointment: 'Your current appointment',
    OrganName: 'Name of Organisation',
    OrganStaffRangeType: 'Size of Organisation',
    OrganStaffRangeType1: '1-20',
    OrganStaffRangeType2: '21~50',
    OrganStaffRangeType3: '51~100',
    OrganStaffRangeType4: 'Over 100',
    JobTitle: 'Job title',
    AppointedDate: 'Date appointed',
    DirectLine: 'Direct line',
    CompanyAddr: 'Company Address',
    Company: 'Company',
    CompanyStreet: 'Number and name of street',
    CompanyDistrictType: 'District',
    DLine: 'Direct fax',
    MLine: 'Main line',
    ResponsibilitiesRemarks: 'Your marketing management responsibilities (You may include no. of staff and budget managed by you or attach a resume)',
    IndustrialCatType: 'Industrial Category',
    Advertising: 'Advertising/Designs/Packaging',
    Hospitality: 'Hospitality/Tourism/Catering/Restaurant',
    Business: 'Business Development',
    ChinaTrade: 'China Trade & Services',
    DirectSelling: 'Direct Selling/Telemarketing',
    FMCGMarketing: 'FMCG Marketing/Distribution',
    GeneralMarketing: 'General Marketing/Market Research & Planning',
    MediaJournalism: 'Media/Journalism/Publication',
    EventsExhibition: 'P.R./Events/Exhibition/Marketing Projects',
    SocialMedia: 'Social Media/I.T./e-commerce',
    Retailing: 'Retailing/CRM/Customer Service/Visual Merchandising',
    Trading: 'Trading/Wholesaling/Merchandising/Products Sourcing/General Trading',
    Academic: 'Academic/Education',
    Corporate: 'Corporate Services',
    Engineering: 'Engineering',
    Medical: 'Medical',
    Transport: 'Transport & Logistics',
    General: 'General Management/MNC',
    Government: 'Government/Non-profit',
    Training: 'HR/Training',
    Manufacturing: 'Manufacturing/Industrial',
    Others: 'Others',
    provide: 'You may provide the resume in order to skip section 5 & 6',
    Previous: ' Previous marketing work experience (in reversed chronological order)',
    Previous1: 'From',
    Previous2: 'To',
    Previous3: 'Company',
    Previous4: 'Position held',
    Previous5: 'Marketing Management Responsibilities',
    educational: 'Your educational background & professional affiliation (in reversed chronological order)',
    educational1: 'From',
    educational2: 'To',
    educational3: 'Institute or professional body',
    educational4: 'Qualification or membership',
    educational5: 'Major or concentration',
    HowHKIM: 'How do you know HKIM',
    Introduce: 'Introduce by HKIM members',
    Namereferee: 'Name of referee',
    HKIMwebsite: 'HKIM website',
    Newsletter: 'HKIM eDM/ Newsletter',
    Exhibition: 'Exhibition/Talk/Seminar',
    ProM: 'ProM Qualification',
    qualifications: 'I would like to receive information about ProM qualifications',
    agreement: 'Our mutual agreement',
    payment: 'Method of payment',
    payment1: 'I enclose the application fee of HK$350 which I understand that it is non-refundable. I further enclose the first annual subscription fee (HK$980) for individual member, I agree to pay the outstanding amount if the annual subscription fee of the elected grade is higher, and understand I can get the amount differences refund if the annual subscriptionfee of the elected grade is lower/when my application is turned down.',
    payment2: 'I enclose the annual renewal fee (HK$980) for individual member, I agree to pay the outstanding amount if the annual renewal fee of the elected grade is higher, and understand I can get the amount differences refund if the annual renewal fee of the elected grade is lower/when my application is turned down.',
    Wehaveprovided: 'Credit card payment for renewal fee (HK$980) or new join member (application fee HK$350 + annual subscription fee HK$980, total HK$1,330) by completing the information and signing below.',
    Collection: 'I will collect my certificate in-person',
    Collection1: 'Please send my certificate by mail',
    IsStarTargettip: 'Only 2 can be checked, please check again',
    Payme: 'Payme',
    WechatPay: 'Wechat Pay',
    Alipay: 'Alipay',
    AlipayHK: 'AlipayHK'
  },
  StudentFrom: {
    StudentMembershipApplication: 'Student Membership Application',
    ForFullTimeStudentsOnly: 'For Full-Time Students Only',
    TipsA: 'Hong Kong Institute of Marketing provides a platform for students to enhance their understanding of marketing theories and practices,interact with other marketers, and broaden their professional knowledge through exposure tours to company or organizations, and customized seminars. Student Members can join activities at special fee rates.',
    TipsB: 'I like to be a Student Member of HKIM, and hereby submit my application. I agree to provide all necessary academic reference for the membership application. (Please provide copies of HKID card and Student ID card with this completed form for application.)',
    PersonalDetails: 'Personal Details ',
    Title: 'Title',
    Mr: 'Mr.',
    Ms: 'Ms.',
    SurnameEnglish: 'Surname (English) ',
    ForenameEnglish: 'Forename (English)',
    Nickname: 'Nickname',
    NameinChinese: 'Name in Chinese',
    PassportNo: 'Passport No./HKID No.',
    Dateofbirth: 'Date of birth',
    eMailA: 'e-Mail(1)',
    eMailB: 'e-Mail(2)',
    EmailTips: '*Since the Institute will communicate with you by e-mails, kindly provide us with your most frequently used e-mail account(s).',
    Mobile: 'Mobile',
    HomeTelNo: 'Home Tel No',
    MailingAddress: 'Mailing Address',
    DistrictType: ' DistrictType',
    HongKong: 'Hong Kong',
    Kowloon: 'Kowloon',
    NewTerritories: 'New Territories',
    Overseas: 'Overseas',
    StudentMembership: 'Student Membership',
    coursetitle: ' Please fill in the course title and year of your study',
    NameofInstitute: 'Name of Institute',
    Programme: 'Programme',
    Period: 'Period',
    courseTipsA: 'I declare that the information given in this form is true and correct to the best of my knowledge and belief. I hereby make application for registration as a student member of HKIM and undertake, if registered, that so long as I remain a registered student member of HKIM, I shall observe and abide by the Code of Practice of the Institute, which is adopted by the Institute from time to time. I understand and agree that memberships of Hong Kong Institute of Marketing are valid only for the year(s) approved. I hereby authorise HKIM to conduct background check with my current and previous education institutes which I attended as the Institute’s deem reasonable and appropriate. ',
    courseTipsB: ' I shall comply with the HKIM Privacy Policy Statement, the Hong Kong Personal Data (Privacy) Ordinance (Cap.486) and other related regulations which will be changed from time to time. I declare that the data given in support of this application are, to the best of my knowledge, true, accurate and complete. I understand that the data will be used in the application process and that any misrepresentation, omission or misleading information given may disqualify my application for membership. I understand that, upon my application, the data will become part of the HKIM record and may be used and processed for all lawful purposes relating to my academic and/or non-academic activities in accordance with the established policy of the HKIM and the Hong Kong Personal Data (Privacy) Ordinance (Cap.486). ',
    Declaration: 'Declaration',
    DeclarationTips: 'I authorize Hong Kong Institute of Marketing (HKIM) to use my data to keep me informed of any direct marketing information including training and education programmes, awards and competitions, membership, alumni, promotional activities and other services and activities that it may arrange. ',
    Submittedsuccessfully: 'Submitted Successfully',
    PleaseprovideStudentIDCard: 'Please provide Student ID Card Copy (PDF/JPG Fomat)'
  }

};
